<template>
  <form ref="formSolicitudeCF" @submit.prevent="sendComplaint" class="w-full px-0 py-0">
    <div v-if="customer == undefined">
      <!-- Persona natural -->
      <div class="w-full" v-if="user && user.person.person_type !== 2">
        <h2 class="my-5 text-lg text-left text-blue"><b>Datos de contacto</b></h2>
        <BaseInput type="text" label="Nombres" v-model="user.first_name" placeholder="Julio Roman" :disabled="true" />
        <BaseInput type="text" label="Apellidos" v-model="user.last_name" placeholder="Henriquez" :disabled="true" />
        <BaseInput type="text" label="Tipo de identificación" v-model="user.person.identification_type.name"
          placeholder="C.C" :disabled="true" />
        <BaseInput type="text" label="Número de identificación" placeholder="Ej:123456789"
          v-model="user.person.identification_number" :disabled="true" />
        <BaseInput type="text" label="Fecha de nacimiento" v-model="user.person.birth_date" placeholder="11/11/2011"
          :disabled="true" />
        <BaseInput type="text" label="Número de celular" placeholder="123412341234" v-model="user.phone"
          :disabled="true" />
        <BaseInput ref="email" type="text" label="Correo electrónico" v-model="user.email"
          placeholder="ejemplo@ejemplo.com" required="true" autocomplete="username" :disabled="true" />

        <BaseSelect ref="department" id="department" label="Seleccione un departamento"
          :selectOptions="formOptions.optionsDepartments" v-model="user.departamento_cod" :disabled="true"
          v-if="user.departamento_cod" />
        <BaseSelect ref="city" id="city" label="Seleccione un municipio" :selectOptions="optionsMunicipalities2"
          v-model="user.municipio_cod" :disabled="true" v-if="user.municipio_cod" />
        <BaseInput ref="address" id="address" label="Dirección" v-model="user.address" :maxlength="50"
          placeholder="Cra. 1 ## 1A-11" type="text" v-if="user.address" :disabled="true" />
      </div>
      <!-- Persona júridica -->
      <div class="w-full" v-if="user && user.person.person_type == 2">
        <h2 class="my-5 text-lg text-left text-blue"><b>Datos de contacto</b></h2>
        <BaseInput type="text" label="Nombres" v-model="user.first_name" placeholder="Julio Roman" :disabled="true" />
        <BaseInput type="text" label="Apellidos" v-model="user.last_name" placeholder="Henriquez" :disabled="true" />
        <BaseInput ref="nit" type="text" id="nit" label="NIT" v-model="user.person.identification_number" :disabled="true"
          v-if="user.person.identification_number" />
        <BaseInput ref="company_name" type="text" id="razonSocial" label="Razón social" v-model="user.business_name"
          :disabled="true" v-if="user.business_name" />
        <BaseInput type="text" label="Número de celular" placeholder="123412341234" v-model="user.phone"
          :disabled="true" />
        <BaseInput ref="email" type="text" label="Correo electrónico" v-model="user.email"
          placeholder="ejemplo@ejemplo.com" required="true" autocomplete="username" :disabled="true" />

        <BaseSelect ref="department" id="department" label="Seleccione un departamento"
          :selectOptions="formOptions.optionsDepartments" v-model="user.departamento_cod" :disabled="true"
          v-if="user.departamento_cod" />
        <BaseSelect ref="city" id="city" label="Seleccione un municipio" :selectOptions="optionsMunicipalities2"
          v-model="user.municipio_cod" :disabled="true" v-if="user.municipio_cod" />
        <BaseInput ref="address" id="address" label="Dirección" v-model="user.address" :maxlength="50"
          placeholder="Cra. 1 ## 1A-11" type="text" v-if="user.address" :disabled="true" />
      </div>
    </div>
    <BaseSelect ref="sexo" id="sexo" label="Sexo" :selectOptions="formOptions.optionsGender" v-model="form.sexo" />
    <BaseRadio ref="lgtbiq" name="lgtbiq" label="LGTBIQ+" :radioOptions="optionsYN" v-model="form.lgbtiq" />
    <BaseRadio ref="especialCondicion" name="especialCondicion" label="¿Tiene alguna condición especial?"
      :radioOptions="optionsYN" v-model="specialCondition" />
    <BaseSelect ref="typeSpecialCondition" v-if="specialCondition == 1" id="typeSpecialCondition" label="¿Cuál?"
      :selectOptions="formOptions.optionsConditions" v-model="form.condicion_especial" required="true" />
    <div class="w-full mb-5">
      <h2 class="my-5 text-xl text-left text-blue">
        <b>Detalles de la inconformidad</b>
      </h2>
      <BaseRadio ref="abroad" name="abroad" label="¿La inconformidad que motiva su queja ocurrió el exterior?"
        :radioOptions="optionsYN" v-model="abroad" required="true" />
      <div v-if="abroad == 2">
        <BaseSelect ref="department" id="department" label="Seleccione un departamento"
          :selectOptions="formOptions.optionsDepartments" v-model="form.departamento_cod" required="true" />
        <BaseSelect ref="city" id="city" label="Seleccione un municipio" :selectOptions="optionsMunicipalities"
          v-model="form.municipio_cod" required="true" />
      </div>
      <div v-if="abroad == 1">
        <BaseSelect ref="country" id="country" label="Seleccione el país donde ocurrio la incidencia"
          :selectOptions="formOptions.optionsCountries" v-model="form.codigo_pais" required="true" />
      </div>
      <BaseSelect ref="entity" id="entity" label="¿Con qué entidad tuvo su inconformidad?"
        :selectOptions="formOptions.optionsCompanies" v-model="form.entidad_cod" required="true" />
      <BaseSelect ref="reason" id="reason" label="¿Cuál es el motivo de su inconformidad?" :selectOptions="optionsReasons"
        v-model="form.macro_motivo_cod" required="true" @change="changeOptionSelect" :getObjectValue="true"/>
      <BaseCheckbox
        v-if="show_is_habeas_data"
        @isJustOneValueSelected="checkHabeasData"
        id="habeas_data"
        :name="'habeas_data'"
        :showAll="false"
        class="my-8"
        label="Si buscas que la SFC revise tu caso para la corrección, actualización, eliminación o retiro de tus datos financieros en los operadores o centrales de información, marca la casilla."
        :options="[{id: 1, name: 'Acepto'}]"
      />
      <BaseSelect ref="product" id="product"
        label="¿Su inconformidad está relacionada con alguno de los siguientes productos?"
        :selectOptions="optionsProducts" v-model="form.producto_cod" required="true" />
      <BaseTextarea ref="productDescription" id="productdescription"
        label="Si lo desea amplíe el detalle de este producto" placeholder="Descripción" v-model="form.producto_nombre"
        required="true" maxlength="100" rows="2" />
      <BaseSelect ref="channel" id="channel" label="¿A través de que canal se originó su inconformidad?"
        :selectOptions="formOptions.optionsChannel" v-model="form.canal_cod" />
      <BaseTextarea ref="describe" id="describe" label="Describa los hechos y qué desea Ud. obtener de la entidad vigilada con este trámite"
        placeholder="Mensaje" v-model="form.texto_queja" required="true" maxlength="4500" />

      <BaseRadio ref="attorney" name="apoderado" label="¿Actúa en nombre propio o es apoderado del consumidor financiero?"
        :radioOptions="optionsApoderado" v-model="form.es_apoderado" required="true" />
      <div v-if="form.es_apoderado == 2" class="w-full">
        <BaseInput ref="attorneyName" type="text" id="apoderadoName" label="Nombre completo del apoderado:"
          placeholder="Ej: Lina Maria De Castro Jaramillo" v-model="form.apoderado_nombre" required="true" />

        <BaseInput ref="attorneyEmail" type="text" label="Correo electrónico del apoderado"
          v-model="form.apoderado_correo" placeholder="ejemplo@ejemplo.com" required="true" autocomplete="username" />

        <DragDrop id="authorizationFiles" @files-handler="setAuthorizationFiles" required="true"
          :fileList="authorizationFiles" label="Anexar autorización de poder:" buttonLabel="SELECCIONE SUS ARCHIVOS" /> 
      </div>
    </div>
    <DragDrop label="En caso de que quiera enviar soportes de los hechos mencionados, adjúntelos aquí:"
      buttonLabel="SELECCIONE SUS ARCHIVOS" id="complaintFiles" :fileList="complaintFiles"
      @files-handler="setComplaintFiles" />
      <p class="my-2 text-xs text-center text-gray-500">
        *Peso máximo de soportes y anexo de autorización de poder 30MB
      </p>
    <!--v-if="(form.indemnizacion == 1 && form.is_demand) || form.is_complaint || form.is_conciliation"-->

    <h2 class="mt-8 text-lg text-left text-blue"><b>Trámite que desea solicitar</b></h2>
    <p class="mt-2 mb-6 text-left text-xs text-gray-400">
      Recuerde que puede seleccionar una, dos o tres dependiendo de su necesidad.
    </p>

    <div class="w-full my-5 flex flex-row p-0 m-0 text-left">

      <a-checkbox key="complaint_cb" class="mr-2" v-model="form.is_complaint">
      </a-checkbox>
      <p class="ml-2" @click="form.is_complaint = !form.is_complaint">
        <b>Queja:</b>
        Su solicitud será atendida directamente por la entidad financiera.
      </p>
    </div>

    <div class="w-full my-5 flex flex-row p-0 m-0 text-left">

      <a-checkbox key="conciliation_cb" v-model="form.is_conciliation" class="mr-2">
      </a-checkbox>
      <p class="ml-2" @click="form.is_conciliation = !form.is_conciliation">
        <b>Conciliación:</b>
        Será convocado para que asista a una audiencia junto con la entidad para buscar
        una solución a su inconformidad, con la participación de un Conciliador de la Superfinanciera.
      </p>
    </div>

    <div class="w-full my-5 flex flex-row p-0 m-0 text-left">

      <a-checkbox key="demand_cb" v-model="form.is_demand" class="mr-2">
      </a-checkbox>
      <p class="ml-2" @click="form.is_demand = !form.is_demand">
        <b>Demanda:</b>
        Se adelanta un proceso con un funcionario de la Superfinanciera quien actúa como Juez entre
        usted y la entidad para decidir sobre su inconformidad.
      </p>
    </div>

    <!--
      <a-checkbox-group
        v-model="ops"
        name="checkboxgroup"
        :options="plainOptions"
        class="text-left"
      >
      <a-checkbox class="mr-8"></a-checkbox>
        <span slot="label" slot-scope="{ title, subtitle }">
          <b>{{ title }}</b> {{ subtitle }}
        </span>
      </a-checkbox-group>
      {{ ops }}
    -->

    <div class="mt-2 flex flex-col gap-4 w-full" v-if="form.is_complaint || form.is_conciliation || form.is_demand">
      <h2 class="mt-8 text-lg text-left text-blue" v-if="form.is_demand || form.is_conciliation">
        <b v-if="form.is_demand && form.is_conciliation">Información de la demanda y conciliación</b>
        <b v-else-if="form.is_conciliation">Información de la conciliación</b>
        <b v-else>Información de la demanda</b>
      </h2>
      <BaseRadio v-if="form.is_demand || form.is_conciliation" ref="cuantiayn" name="cuantiayn" :disabled="cuantiaYNDisabled"
        label="¿Quiere ingresar cuantía?" :radioOptions="optionsYN" v-model="cuantiaYN" required="true" />    

      <div  v-if="(form.is_demand || form.is_conciliation) && cuantiaYN == 1" class="h-full w-full mt-5 text-left flex flex-col">
        <label
          class="text-xs md:text-xs text-gray-500 text-opacity-80"
          ><b>Cuantía (Ingrese el valor en pesos, sin puntos ni comas)</b
          ><span class="text-red-500">*</span></label
        >
        <a-input-number
          v-model="form.cuantia"
          class="w-full mt-2 border rounded-lg text-xs md:text-sm"
          :id="'cuantia_value'"
          :name="'cuantia_value'"
          placeholder="Ej: 500,000"
          :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          :parser="value => value.replace(/\$\s?|(,*)/g, '')"
          @change="onChangeNumber"
          autocomplete="on"
        />
      </div>
      
      <p v-if="(form.is_demand || form.is_conciliation) && cuantiaYN == 1 && invalidCuantia"
        class="mt-2 text-xs text-left text-red-500">
        {{ invalidCuantia }}
      </p>
      <BaseRadio v-if="form.is_demand" ref="indemnizacionyn" name="indemnizacionyn" label="¿Pretende indemnización?"
        :radioOptions="optionsYN" v-model="form.indemnizacion" required="true" />
      <div class="w-full mb-2 text-xs md:text-xs flex-col text-left text-gray-500 text-opacity-80"
        v-if="form.indemnizacion == 1 && form.is_demand">
        <BaseRadio label="Juramento estimatorio:" required="true" />
        <a-checkbox :key="'jura_cb'" ref="jura_indemnizacionyn" v-model="form.juramento">
          Estimo bajo la gravedad del juramento que el valor de mis
          pretensiones asciende a las indicadas
        </a-checkbox>
        <p v-if="invalidJura" class="mt-2 text-xs text-left text-red-500">
          Este campo es obligatorio
        </p>
      </div>

      <p v-if="feedback" class="text-sm text-center text-red-500">
        {{ feedback }}
      </p>
      <br />
      <a-button v-if="form.is_complaint && !(form.is_demand || form.is_conciliation)" html-type="submit" type="primary"
        shape="round" :block="true" :loading="loading">
        REGISTRAR QUEJA
      </a-button>
      <a-button v-else type="primary" shape="round" :block="true" :loading="loading" @click="prepareModal">
        GUARDAR
      </a-button>
      <a-button type="danger" shape="round" :block="true" ghost @click.native="$router.push('/')">
        VOLVER
      </a-button>
      <a-modal v-model="modalVisible" centered shape="round" :footer="null" :width="'60%'">
        <a-row class="h-full">
          <a-col :span="24" :sm="24" :lg="{ span: 14 }" class="px-4 h-full">
            <div class="mb-4">
              <a-steps v-model="currentStepFile" type="navigation" size="small" :style="stepStyle">
                <a-step v-if="form.is_conciliation" title="Conciliación">
                  <a-icon slot="icon" type="reconciliation" theme="twoTone" />
                </a-step>
                <a-step v-if="form.is_demand" title="Demanda">
                  <a-icon slot="icon" type="bank" theme="twoTone" />
                </a-step>
              </a-steps>
            </div>
            <div v-if="loadingModal" class="mt-8">
              <a-spin tip="Generando archivo 。。。" size="large">
                <div class="spin-content text-center mt-16">
                  Si el documento no se pudo cargar puedes descargar una copia.
                </div>
              </a-spin>
            </div>
            <iframe title="Previsualizacion de documento cargado" v-if="form.is_conciliation && currentStepFile == 0" class="w-full h-96" @load="loadedFile"
              :src="'https://docs.google.com/gview?url=' + encodeURIComponent(evidences.conciliation.url) + '&embedded=true'"
              ></iframe>
            <iframe title="Previsualizacion de documento cargado"
              v-if="form.is_conciliation ? (form.is_demand && currentStepFile == 1) : (form.is_demand && currentStepFile == 0)"
              class="w-full h-96" @load="loadedFile"
              :src="'https://docs.google.com/gview?url=' + encodeURIComponent(evidences.demand.url) + '&embedded=true'"
              ></iframe>

          </a-col>
          <a-col :span="24" :sm="24" :lg="{ span: 10 }" class="px-2">
            <h1 class="mt-6 text-2xl text-left text-blue"><b>Vista previa y envío</b></h1>
            <p class="my-4">
              Esta es la vista previa de su solicitud, revise que todo esté en orden antes de enviarlo,
              pues después de aceptar ya no será posible hacer modificaciones.<br /><br />
              En caso de que desee evidencia de esta solicitud puede descargar copia del documento
              presionando el botón "DESCARGAR COPIA".
            </p>
            <div class="px-16 mt-2">
              <a-button class="my-4" type="primary" shape="round" :block="true" ghost @click="downloadFile">
                DESCARGAR COPIA
              </a-button>
              <a-button class="my-4" type="primary" shape="round" :block="true" @click="sendComplaint">
                ENVIAR SOLICITUD
              </a-button>
              <a-button class="my-4" type="danger" shape="round" :block="true" ghost @click="modalVisible = false">
                VOLVER
              </a-button>
            </div>
          </a-col>
        </a-row>
      </a-modal>
    </div>
    <div v-if="loading" class="fixed inset-0 flex justify-center items-center">
      <a-spin tip="Cargando..." size="large">
      </a-spin>
    </div>
  </form>
</template>

<script>
import checkFileSize from "@/utils/checkFileSize";
export default {
  props: ["customer","checkSensitiveData","checkPersonalData"],
  data() {
    return {
      plainOptions: [
        {
          title: 'Conciliación:',
          subtitle: `Será convocado para que asista a una audiencia junto con la entidad para buscar una solución a su inconformidad, con la participación de un Conciliador de la Superfinanciera.`,
          value: '2'
        },
        {
          title: 'Demanda:',
          subtitle: `Se adelanta un proceso con un funcionario de la Superfinanciera quien actúa como Juez entre usted y la entidad para decidir sobre su inconformidad.`,
          value: '1'
        },
        {
          title: 'Queja:',
          subtitle: `Su solicitud será atendida directamente por la entidad financiera.`,
          value: '0'
        },
      ],
      optionsForm: [false, false, false],
      modalVisible: false,
      evidences: {
        conciliation: {
          url: null,
          folder: null
        },
        demand: {
          url: null,
          folder: null
        }
      },
      cuantiaYN: "",
      cuantiaYNDisabled: false,
      ops: [],
      show_is_habeas_data: false,
      form: {
        type_form: [],
        tipo_entidad: "",
        company_type_name: "",
        company_sfc_code: "",
        entidad_cod: "",
        nombres: "",
        texto_queja: "",
        lgbtiq: "",
        codigo_pais: "",
        departamento_cod: "",
        municipio_cod: "",
        tipo_id_CF: "",
        numero_id_CF: "",
        tipo_persona: "",
        canal_cod: "",
        producto_cod: "",
        producto_nombre: "",
        macro_motivo_cod: "",
        check_habeas_data: false,
        sexo: "",
        condicion_especial: "",
        apoderado_nombre: "",
        apoderado_correo: "",
        es_apoderado: 1,
        insta_recepcion: 1,
        punto_recepcion: 1,
        admision: 9,
        desistimiento_queja: 2,
        cuantia: "",
        indemnizacion: "",         
        juramento: false,
        files_names: [],
        files_representative_names: [],
        demand_file_save_in: null,
        conciliation_file_save_in: null,
        demand_cero_file: {},
        conciliation_cero_file: {},
        is_complaint: false,
        is_conciliation: false,
        is_demand: false
      },
      invalidJura: false,
      invalidCuantia: "",
      feedback: "",
      abroad: "",
      specialCondition: "",
      complaintFiles: [],
      authorizationFiles: [],
      filesSize: 0,
      optionsMunicipalities: [],
      optionsMunicipalities2: [],
      optionsReasons: [],
      optionsProducts: [],
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      optionsApoderado: [
        { id: 1, name: "Actúo en nombre propio" },
        { id: 2, name: "Soy apoderado" },
      ],
      disableButton: false,
      loading: false,
      loadingModal: false,
      currentStepFile: 0,
      stepStyle: {
        boxShadow: '0px -1px 0 0 #e8e8e8 inset',
      },
      smmlv: 1300000,
      smmlvMax: 52000000
    };
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    user: function () {
      let user = this.$store.state.session.user;

      console.log('user CForm 1', user)
      console.log('customer CForm 2', this.customer)

      if (this.customer) {

        if (this.customer.person_type == 2) {
          this.form.nombres = this.customer.business_name;
        } else {
          this.form.nombres = this.customer.first_name + " " + this.customer.last_name;
        }
        this.form.user_id_CF = this.customer.user_id
        this.form.tipo_id_CF = this.customer.identification_type;
        this.form.numero_id_CF = this.customer.identification_number;
        this.form.tipo_persona = this.customer.person_type;
      }
      else {
        // CC: Si es persona júridica solo se manda el nombre
        if (user.person.person_type == 2) {
          this.form.nombres = user.business_name;
        } else {
          this.form.nombres = user.first_name + " " + user.last_name;
        }
        this.form.user_id_CF = user.id
        this.form.tipo_id_CF = user.person.identification_type.id;
        this.form.numero_id_CF = user.person.identification_number;
        this.form.tipo_persona = user.person.person_type;
      }
      this.form.creator_id = user.id;
      this.form.creator_type_id = user.person.identification_type.id;
      this.form.creator_number_id = user.person.identification_number;
      this.form.creator_email = user.email;
      this.form.creator_username = user.username;
      this.form.creator_full_name = user.first_name + ' ' + user.last_name;

      console.log('this.form', this.form)

      return user;
    },
  },
  methods: {
    onChangeNumber(value) {
      this.invalidCuantia = "";
      const cuantia = String(this.form.cuantia);
      if (cuantia.includes(',') || cuantia.includes('.')) {
        this.invalidCuantia = "El campo no puede tener ni punto ni coma";
        return;
      }
      const cuantia_number = parseInt(this.form.cuantia);
      if (isNaN(cuantia_number)){
        this.invalidCuantia = "El campo no es un número valido";
      }
      else if (cuantia_number < 0){
        this.invalidCuantia = "El campo no puede ser negativo";
      }
    },
    checkHabeasData(value){
      this.form.check_habeas_data = value;
    },
    changeOptionSelect(value){
      console.log('changeOptionSelect', value);
      this.show_is_habeas_data = value.is_habeas_data;
      if(!this.show_is_habeas_data){
        this.form.check_habeas_data = false;
      }
    },
    async getsmmlv() {
      let { data, error } = await this.$api.getsmmlv();
      if (error) { return }
      console.log('getsmmlv', data);
      this.smmlv = data.smmlv;
      this.smmlvMax = data.max_smmlv;
    },
    loadedFile() {
      this.loadingModal = false;
    },
    buildForm(join_detail = false) {
      console.log('this.form.check_habeas_data', this.form.check_habeas_data)
      this.form.type_form = [];
      if (this.form.is_complaint) this.form.type_form.push("1");
      if (this.form.is_demand) this.form.type_form.push("2");
      if (this.form.is_conciliation) this.form.type_form.push("3");
      let form = {
        ...this.form,
        apoderado_correo:
          this.form.es_apoderado == 2
            ? this.form.apoderado_correo.toLowerCase()
            : "",
      };
      if (!join_detail) return form;
      console.log(this.formOptions)
      let detail_user_form = this.customer ?
        {
          names_cf: this.customer.first_name,
          surnames_cf: this.customer.last_name,
          identification_type_cf: this.customer.identification_type_name,
          email_user: this.customer.email,
          phone_user: this.customer.phone,
          direccion_user: this.customer.address,
          birth_date_user: this.customer.birth_date,
          departamento_nombre_user: this.getNameById(this.formOptions.optionsDepartments, this.customer.departamento_cod),
          municipio_nombre_user: this.getNameById(this.optionsMunicipalities2, this.customer.municipio_cod)
        } :
        {
          names_cf: this.user.first_name,
          surnames_cf: this.user.last_name,
          identification_type_cf: this.user.person?.identification_type?.name,
          email_user: this.user.email,
          phone_user: this.user.phone,
          direccion_user: this.user.address,
          birth_date_user: this.user?.person?.birth_date,
          departamento_nombre_user: this.getNameById(this.formOptions.optionsDepartments, this.user.departamento_cod),
          municipio_nombre_user: this.getNameById(this.optionsMunicipalities2, this.user.municipio_cod)
        };

      let detail_form = {
        company_name: this.getNameById(this.formOptions.optionsCompanies, this.form.entidad_cod),
        motivo_nombre: this.getNameById(this.optionsReasons, this.form.macro_motivo_cod),
        producto_name: this.getNameById(this.optionsProducts, this.form.producto_cod),
        canal_nombre: this.getNameById(this.formOptions.optionsChannel, this.form.canal_cod),
        pais_nombre: this.getNameById(this.formOptions.optionsCountries, this.form.codigo_pais, 'Colombia'),
        departamento_nombre: this.getNameById(this.formOptions.optionsDepartments, this.form.departamento_cod),
        municipio_nombre: this.getNameById(this.optionsMunicipalities, this.form.municipio_cod)
      };


      form = { ...form, ...detail_form, ...detail_user_form ,
               check_sensitive_data:this.checkSensitiveData?this.checkSensitiveData==1:null,
               check_personal_data:this.checkPersonalData?this.checkPersonalData==1:null
             };
      // if(this.checkSensitiveData){
      //   form["check_sensitive_data"] = this.checkSensitiveData == 1;
      // }
      // if(this.checkPersonalData){
      //   form["check_personal_data"] = this.checkPersonalData == 1;
      // }
      // console.log('form', form);
      return form
    },
    downloadFile() {
      const link = document.createElement('a');
      let url = '';
      let file_name = '';
      if (this.currentStepFile == 0 && this.form.is_conciliation) {
        url = this.evidences.conciliation.url;
        file_name = 'conciliacion'
        console.log('downloadFile', file_name, this.evidences.conciliation)
      }
      else {
        url = this.evidences.demand.url;
        file_name = 'demanda'
        console.log('downloadFile', file_name, this.evidences.demand)
      }
      link.href = url;
      link.download = file_name + '.docx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async postURLFileApi(form, type_file) {
      let query = '?type_file=' + type_file;
      let { error, data } = await this.$api.getJurisdictionalFileURL(form, query);
      if (error) {
        this.loading = false;
        this.showError(error, 'Hubo un error al procesar la solicitud. Volver a intentarlo');
        return
      }
      if (data) {
        console.log('file=', type_file, data);
        if (type_file == '1') {
          this.evidences.conciliation.url = data.url;
          this.evidences.conciliation.folder = data.saved_in;
          this.form.conciliation_file_save_in = data.saved_in;
          this.form.conciliation_cero_file = data.attachment;
        } else {
          this.evidences.demand.url = data.url;
          this.evidences.demand.folder = data.saved_in;
          this.form.demand_file_save_in = data.saved_in;
          this.form.demand_cero_file = data.attachment;
        }
      }
    },
    async prepareModal() {
      if (!this.checkForm()) {
        this.disableButton = false;
        this.loading = false;
        this.$message.warning('Parece que faltan algunos detalles en el formulario.');
        return;
      }
      console.log('1. prepareModal')
      this.evidences.conciliation.url = null;
      this.evidences.demand.url = null;
      this.currentStepFile = 0;
      this.loading = true;
      let form = this.buildForm(true);
      console.log('2. prepareModal', form)
      if (this.form.is_conciliation) {
        await this.postURLFileApi(form, '1'); // '#toolbar=0'
      }
      if (this.form.is_demand) {
        await this.postURLFileApi(form, '2');
      }
      this.loading = false;
      this.modalVisible = true;
    },
    getNameById(lista, idBuscado, defaultValue = null) {
      try {
        const elementoEncontrado = lista.find((elemento) => String(elemento.id) === idBuscado);
        if (elementoEncontrado) {
          return elementoEncontrado.name;
        }
      } catch (error) {
        console.error('getNameById - error:', error, 'lista', lista, 'idBuscado', idBuscado);
      }
      return defaultValue;
    },
    showError(error, default_error_msg='Hubo un error al registrar la solicitud') {
      this.loading = false;
      this.disableButton = false;
      if (error.status_code === 400) {
        let err;
        for (let i in error.message) {
          err = error.message[i][0];
        }
        this.feedback = err;
      } else {
        this.feedback = default_error_msg;
      }
    },
    handleCheckboxChange(index) {
      // Cambia el estado de la opción en función del índice
      this.optionsForm[index] = !this.optionsForm[index];
      this.form.type_form = this.optionsForm
        .map((option, idx) => (option ? String(idx + 1) : null))
        .filter(value => value !== null);
    },
    handleOathChange() {
      this.form.juramento = this.form.juramento == 1 ? 2 : 1;
    },
    async sendComplaint() {
      this.disableButton = true;
      this.loading = true;
      this.modalVisible = false;

      if(this.form.is_conciliation && (!this.form.conciliation_file_save_in || this.form.conciliation_file_save_in.length <= 4)){
        this.$message.warning('Parece que hubo un error al cargar un archivo, vuelve a intentarlo.');
        console.log('Not file c', this.form.is_conciliation, this.form.conciliation_file_save_in)
        this.loading = false;
        this.disableButton = false;
        return;
      }

      if(this.form.is_demand && (!this.form.demand_file_save_in || this.form.demand_file_save_in.length <= 4)){
        this.$message.warning('Parece que hubo un error al cargar un archivo, vuelve a intentarlo.');
        console.log('Not file d', this.form.is_demand, this.form.demand_file_save_in)
        this.loading = false;
        this.disableButton = false;
        return;
      }

      if (!this.checkForm()) {
        this.disableButton = false;
        this.loading = false;
        this.$message.warning('Parece que faltan algunos detalles en el formulario.');
        return;
      }
      let files = {
        authorizationFiles: this.authorizationFiles,
        complaintFiles: this.complaintFiles,
      };
      let form = this.buildForm(true);
      
      if (this.form.type_form.includes("1")) { // Queja
        let { error, data } = await this.$api.setComplaint(form, files);
        if (error) {
          this.showError(error);
          return
        }
        form.codigo_queja = data.codigo_queja;
        form.fecha_creacion = data.fecha_creacion;
      }

      let { error, data } = await this.$api.setSolicitude(form, files);
      if (error) {
        this.showError(error);
        return
      }
      if (data) {
        let code = data.codigo_queja ? data.codigo_queja : '';
        let isDemandOrConciliation = this.form.is_conciliation || this.form.is_demand;
        let rateService = data.codigo_queja ? false : true;
        if (this.customer) {
          this.$router.push({
            name: "RegisteredComplaint",
            params: { complaintCode: data.codigo_queja, rated: rateService },
          });
        }
        else {
          this.$store.dispatch("complaints/loadComplaints");
          this.$router.push({
            name: "customerComplaintMessage",
            query: { type: "jurisdictional_registration", complaintCode: code, rated: rateService, isDemandOrConciliation: isDemandOrConciliation },
          });
        }

      }
    },
    setComplaintFiles(files) {
      if (checkFileSize([...files, ...this.authorizationFiles], 30)) {
        this.feedback =
          "Con el archivo que intenta cargar, se supera el peso máximo permitido de los anexos. Valide la información. Si requiere subir más anexos lo puede hacer con el número de radicado en el seguimiento/búsqueda de queja";
      } else {
        this.form.files_names = [];
        files.forEach(file => {
          this.form.files_names.push(file.name);
        });
        console.log(this.form.files_names);
        this.complaintFiles = files;
        this.feedback = "";
      }
    },
    setAuthorizationFiles(files) {
      if (checkFileSize([...files, ...this.complaintFiles], 30)) {
        this.feedback =
          "Con el archivo que intenta cargar, se supera el peso máximo permitido de los anexos. Valide la información. Si requiere subir más anexos lo puede hacer con el número de radicado en el seguimiento/búsqueda de queja";
      } else {
        this.form.files_representative_names = [];
        files.forEach(file => {
          this.form.files_representative_names.push(file.name);
        });
        console.log(this.form.files_representative_names);
        this.authorizationFiles = files;
        this.feedback = "";
      }
    },
    checkForm() {
      this.feedback = "";
      let valid = false;
      this.invalidJura = false;
      let refs = { ...this.$refs };

      for (let ref in refs) {
        if (refs[ref] && refs[ref].required && refs[ref].value === "") {
          let y =
            refs[ref].$el.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo(0, y);
          refs[ref].setInvalid(true);
          return valid;
        }
      };
      if (this.form.indemnizacion == 2) {
        this.form.juramento = false;
      }
      if (refs['jura_indemnizacionyn'] && !refs['jura_indemnizacionyn'].checked) {
        this.invalidJura = true;
        let y = refs['jura_indemnizacionyn'].$el.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo(0, y);
        return valid;
      };

      let regex = /[^a-zñÑ\s]/i;
      if (this.form.texto_queja.length < 30) {
        this.feedback =
          "La descripción de los hecho debe tener más de 30 caracteres";
        return valid;
      } else if (
        this.form.es_apoderado == 2 &&
        regex.test(this.form.apoderado_nombre)
      ) {
        this.feedback =
          "No se permiten acentos ni caracteres especiales en el nombre del apoderado";
        return valid;
      } else if (
        this.form.es_apoderado == 2 &&
        (this.form.apoderado_nombre == "" ||
          this.authorizationFiles.length == 0 ||
          this.form.apoderado_correo == "")
      ) {
        this.feedback =
          "Debe agregar el nombre del apoderado, el email  y el archivo de autorización de poder";
        return valid;
      }
      if (this.cuantiaYN == 1) {
        
        this.form.cuantia = String(this.form.cuantia);
        this.invalidCuantia = "";
        if (this.form.cuantia.includes(',') || this.form.cuantia.includes('.')) {
          this.invalidCuantia = "El campo no puede tener ni punto ni coma";
          return valid;
        }
        let cuantia = parseInt(this.form.cuantia);
        let smmlvMax = parseInt(this.smmlvMax);
        if (isNaN(cuantia)) {
          this.invalidCuantia = "El campo es obligatorio";
          return valid;
        }
        if (cuantia < 0) {
          this.invalidCuantia = "El campo no puede ser negativo";
          return valid;
        }
        console.log('cuantia', cuantia, 'smmlvMax', smmlvMax)
        if (this.form.cuantia && cuantia == 0 && this.form.es_apoderado == 1 && this.form.is_demand) {
          this.feedback =
            "Si la cuantía es igual a cero, es obligatorio poseer un apoderado";
          return valid;
        }
        if (this.form.cuantia && cuantia > smmlvMax && this.form.es_apoderado == 1 && this.form.is_demand) {
          this.feedback =
            "Si la cuantía supera el monto de 40 SMMLV, es obligatorio poseer un apoderado";
          return valid;
        }
      }
      else {
        this.form.cuantia = "";
      }

      if (
        this.form.apoderado_correo &&
        !this.$validator.isEmail(this.form.apoderado_correo)
      ) {
        this.feedback = "Ingrese un correo válido en el apoderado";
        return valid;
      }
      // Validación de datos del usuario
      if (this.form.tipo_id_CF == '' || this.form.tipo_id_CF == undefined) {
        this.feedback = "¡Datos faltantes!(tipo de identificación). Apreciado usuario no se cargó correctamente su información personal, actualice la página y vuela a intentarlo.";
        return valid;
      }
      if (this.form.numero_id_CF == '' || this.form.numero_id_CF == undefined) {
        this.feedback = "¡Datos faltantes!(identificación). Apreciado usuario no se cargó correctamente su información personal, actualice la página y vuela a intentarlo.";
        return valid;
      }
      if (this.form.tipo_persona == '' || this.form.tipo_persona == undefined) {
        this.feedback = "¡Datos faltantes!(persona). Apreciado usuario no se cargó correctamente su información personal, actualice la página y vuela a intentarlo.";
        return valid;
      }

      valid = true;
      this.feedback = "";
      return valid;
    },
    async getMunicipios() {
      if (this.user.departamento_cod == "") {
        return;
      }
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.user.departamento_cod }
      );
      this.optionsMunicipalities2 = options;
    }
  },
  watch: {
    "form.is_demand": function(){
      if(this.form.is_demand && this.form.indemnizacion == 1){
        this.cuantiaYN = 1;
      }
    },
    "form.indemnizacion": function(){
      if(this.form.indemnizacion == 1){
        this.cuantiaYN = 1;
        this.cuantiaYNDisabled = true;
      }
      else if(this.cuantiaYNDisabled){
        this.cuantiaYN = null;
        this.cuantiaYNDisabled = false;
      }
    },
    "cuantiaYN": function(){
      if(this.form.is_demand && this.cuantiaYN == 2){
        this.$message.info('Si Ud. va a presentar una demanda debe tener apoderado para el trámite');
      }
    },
    "customer": async function () {
      if (this.customer) {

        if (this.customer.person_type == 2) {
          this.form.nombres = this.customer.business_name;
        } else {
          this.form.nombres = this.customer.first_name + " " + this.customer.last_name;
        }
        this.form.user_id_CF = this.customer.user_id
        this.form.tipo_id_CF = this.customer.identification_type;
        this.form.numero_id_CF = this.customer.identification_number;
        this.form.tipo_persona = this.customer.person_type;
        }
        else {
        // CC: Si es persona júridica solo se manda el nombre
        if (user.person.person_type == 2) {
          this.form.nombres = this.user.business_name;
        } else {
          this.form.nombres = this.user.first_name + " " + this.user.last_name;
        }
        this.form.user_id_CF = this.user.id
        this.form.tipo_id_CF = this.user.person.identification_type.id;
        this.form.numero_id_CF = this.user.person.identification_number;
        this.form.tipo_persona = this.user.person.person_type;
        }
        this.form.creator_id = this.user.id;
        this.form.creator_type_id = this.user.person.identification_type.id;
        this.form.creator_number_id = this.user.person.identification_number;
        this.form.creator_email = this.user.email;
        this.form.creator_username = this.user.username;
        this.form.creator_full_name = this.user.first_name + ' ' + this.user.last_name;

    },
    "form.departamento_cod": async function () {
      if (this.form.departamento_cod == "") {
        return;
      }
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.form.departamento_cod }
      );
      this.optionsMunicipalities = options;
    },
    "form.entidad_cod": async function (value) {
      if (value == "") {
        return;
      }
      // Reiniciar valores
      this.form.macro_motivo_cod = "";
      this.form.producto_cod = "";
      let company = this.formOptions.optionsCompanies.find((company) => {
        return company.id == value;
      });
      this.form.tipo_entidad = company.company_type;
      this.form.company_type_name = company.company_type_name;
      this.form.company_sfc_code = company.sfc_code;
      let optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        { company: this.form.entidad_cod }
      );
      let optionsProducts = await this.$store.dispatch(
        "form/loadProductsOptions",
        { company: this.form.entidad_cod }
      );
      this.optionsReasons = optionsReasons;
      this.optionsProducts = optionsProducts;
    },
    abroad: function () {
      this.form.departamento_cod = "";
      this.form.municipio_cod = "";
      this.form.codigo_pais = "";
      if (this.abroad == 2) {
        this.form.codigo_pais = "170";
      }
    },
    specialCondition: function () {
      if (this.specialCondition == 2) {
        this.form.condicion_especial = 98;
      } else {
        this.form.condicion_especial = "";
      }
    },
    "form.es_apoderado"() {
      if (this.form.es_apoderado === 1) {
        this.form.apoderado_nombre = "";
        this.form.apoderado_correo = "";
      }
    },
    "form.is_complaint"() {
      this.$emit('complaintSelected', this.form.is_complaint);
    },
  },
  async created() {
    await this.getMunicipios();
    await this.getsmmlv();
    console.log('customercustomer', this.customer)
  }
};
</script>